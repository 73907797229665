import React from "react";

import { DetailedHTMLProps } from "react";
import { FormEvent } from "react";
import { FormHTMLAttributes } from "react";
import { Link } from "gatsby"
import { MouseEvent } from "react";
import { Ref } from "react";

import clsx from "clsx";

import Button from "@js/component/Button";
import Input from "@js/component/Input";

import * as classNames from "@css/component/ForgotPasswordForm.module.scss";

/**
 * @type ForgotPasswordFormProps
 */
export type ForgotPasswordFormProps = Omit<DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, "children"> & {
    emailValue?: string;
    errors: { [key: string]: string[]; };
    formRef?: Ref<HTMLFormElement>;
    isSending: boolean;
    onButtonClick?: (event: MouseEvent) => void;
    onEmailChange?: (event: FormEvent<HTMLInputElement>) => void;
};

/**
 * @const ForgotPasswordForm
 */
const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
    const {
        className,
        emailValue,
        errors,
        formRef,
        isSending,
        onButtonClick,
        onEmailChange,
        ...restProps
    } = props;

    return (
        <form { ...restProps } className={ clsx(classNames.forgotPasswordForm, className) } ref={ formRef }>
            <label className={ classNames.label } htmlFor="email">E-mailadres</label>
            <Input
                className={ clsx(classNames.input, {
                    [classNames.error]: errors.email
                }) }
                id="email"
                name="email"
                onChange={ onEmailChange }
                placeholder="Typ hier je e-mailadres"
                // required
                type="email"
                value={ emailValue }
            />

            <ul className={ classNames.errors }>
                { errors.email &&
                  errors.email.map((error, index) => (
                    <li className={ classNames.error } key={ index }>{ error }</li>
                )) }
            </ul>

            <Button
                className={ classNames.button }
                disabled={ isSending }
                onClick={ onButtonClick }
                type="submit"
            >
                { !(isSending) && "Versturen" }
                { isSending && "Versturen..." }
            </Button>
            <Link className={ classNames.link } to="/portaal/login">
                Terug naar inloggen
            </Link>
        </form>
    );
};

export default ForgotPasswordForm;
