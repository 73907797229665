import React from "react";

import { Helmet } from "react-helmet";

import { MouseEvent } from "react";

import { navigate } from "gatsby";

import { createRef } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";

import Box from "@js/component/Box";
import ForgotPasswordForm from "@js/component/ForgotPasswordForm";
import Heading from "@js/component/Heading";
import Main from "@js/component/Main";

import { isLoggedIn } from "@js/services/auth";

import * as classNames from "@css/component/template/portal/ForgotPassword.module.scss";


/**
 * @const ForgotPassword
 */
const ForgotPassword = () => {
    const formRef = createRef<HTMLFormElement>();

    const [email, setEmail] = useState("");

    const [isSending, setIsSending] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string[]; }>({});

    const onButtonClick = async (event: MouseEvent) => {
        event.preventDefault();

        if (formRef.current?.reportValidity()) {
            setIsSending(true);

            const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/advisor/passwordreset`, {
                body: JSON.stringify({
                    email: email
                }),
                method: "POST"
            });

            const data = await response.json();

            if (data.status == "failed") {
                setErrors(data.errors);
            }
            else {
                setIsDone(true);
            }

            setIsSending(false);
        }
    };

    useLayoutEffect(() => {
        if (isLoggedIn()) {
            navigate("/portaal/dashboard", { replace: true });
        }
    }, []);

    return (
        <Main className={classNames.main}>
            <Helmet>
                <title>Wachtwoord vergeten</title>
            </Helmet>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h1" variant="large">
                    Wachtwoord <strong>vergeten</strong>
                </Heading>
                <Box className={ classNames.box }>
                    { isDone &&
                        <div className={ classNames.done }>Er is een e-mail gestuurd met daarin een nieuw wachtwoord.</div>
                    }
                    { !(isDone) &&
                        <ForgotPasswordForm
                            emailValue={ email }
                            errors={ errors }
                            formRef={ formRef }
                            isSending={ isSending }
                            onButtonClick={ onButtonClick }
                            onEmailChange={ (event) => setEmail(event.currentTarget.value) }
                        />
                    }
                </Box>
            </div>
        </Main>
    );
};

export default ForgotPassword;
