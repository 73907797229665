import React from "react";

import { DetailedHTMLProps } from "react";
import { InputHTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Input.module.scss";

/**
 * @type InputProps
 */
export type InputProps = Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "children"> & {
    inputRef?: Ref<HTMLInputElement>;
};

/**
 * @const Input
 */
const Input = (props: InputProps) => {
    const {
        className,
        inputRef,
        ...restProps
    } = props;

    return (
        <input { ...restProps } className={ clsx(classNames.input, className) } ref={ inputRef } />
    );
};

export default Input;
